import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Logo from "../../../assets/images/logo_medium.png";

const useStyles = makeStyles(() => ({
  root: {
    height: "95vh",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  logo: {
    marginBottom: "20px",
  },
}));

export default function LoadingComponent() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img className={classes.logo} src={Logo} alt="" />
      <CircularProgress />
    </div>
  );
}
