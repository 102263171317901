import MaterialTable from "material-table";
import React from "react";
import { useSelector } from "react-redux";
import { localization, useTableStyles } from "./config/tableConfig";
import TitleContainer from "./utilsTable/titleWithFetchData";

const ProviderInUserTable = ({ data, handleOpenEdit, handleDelete }) => {
  const classes = useStyles();
  const tableClasses = useTableStyles()();
  const esSoporte = useSelector(
    (state) => state.auth.roles?.tipo === "Soporte",
  );

  const columns = [
    {
      title: "Prestador",
      field: "nombre",
    },
  ];

  return (
    <div className={`${tableClasses.tablePadding} ${classes.background}`}>
      <MaterialTable
        columns={columns}
        data={data}
        locale="es"
        title={<TitleContainer title="Prestadores" />}
        options={{
          actionsColumnIndex: -1,
          rowStyle: localization.rowStyle,
          headerStyle: localization.headerStyleWithBG,
          pageSize: 5,
          search: false,
          filtering: true,
          exportButton: false,
        }}
        actions={[
          // {
          //   icon: "edit",
          //   tooltip: "Editar Usuario",
          //   onClick: (event, rowData) => handleOpenEdit(rowData),
          // },
          {
            icon: "delete",
            tooltip: "Eliminar Prestador",
            onClick: (event, rowData) => handleDelete(rowData),
            disabled: esSoporte,
          },
        ]}
        localization={{
          ...localization,
          body: {
            emptyDataSourceMessage: "No hay prestadores que mostrar",
            filterRow: {
              filterTooltip: "Filtrar",
            },
          },
        }}
      />
    </div>
  );
};
export default ProviderInUserTable;
