import { FormControlLabel, MenuItem, Radio } from "@material-ui/core";
import { addDays } from "date-fns";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFormDiagnosis } from "../../../../actions/forms";
import {
  getCantidadDiasBajaMedica,
  getFechaMasReciente,
} from "../../../../utils/datetimeUtils";
import DarkerDisabledTextField from "../../../commons/formComponents/darkerDisabledTextField";
import FormikActions from "../../../commons/formik/formikActions";
import FormikAutocomplete from "../../../commons/formik/formikAutocomplete";
import FormikCheckbox from "../../../commons/formik/formikCheckbox";
import FormikDatePicker from "../../../commons/formik/formikDatePicker";
import FormikDateTimePicker from "../../../commons/formik/formikDateTimePicker";
import FormikRadio from "../../../commons/formik/formikRadio";
import FormikSelect from "../../../commons/formik/formikSelect";
import FormikTextFieldSpeech from "../../../commons/formik/formikTextFieldSpeech";
import DropZone from "../dropZone/dropZone";
import FormSectionDivider from "../formSectionDivider";
import { useStyles } from "../formStyle";
import { amValidation, validateRequiredFields } from "../formValidation";
import ProfessionalControl from "../professionalControl/professionalControl";
import EspecialidadesContainer from "./especialidadesContainer";

const Am = ({
  handleClose,
  files,
  setFiles,
  handleSubmit,
  model,
  formValues,
  selectedDocument,
  especialidades,
  setEspecialidades,
  isView,
  disabled,
  idArt,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const diagnosis = useSelector((state) => state.forms.diagnosis);
  const fields = model.fields;

  const [attachmentError, setAttachmentError] = useState(false);
  const [openAddEspecialidades, setOpenAddEspecialidades] = useState(false);
  const [professionalError, setProfessionalError] = useState(false);

  const fechaAltaMinDate = model.configuration.fechaAltaMin
    ? fields.fechaAlta.validations && fields.fechaAlta.validations.minDate
      ? getFechaMasReciente(
        fields.fechaAlta.validations.minDate,
        fields.fechaPrimeraCuracion.options,
      )
      : fields.fechaPrimeraCuracion.options
    : fields.fechaAlta.validations && fields.fechaAlta.validations.minDate
      ? fields.fechaAlta.validations.minDate
      : undefined;

  return (
    <Formik
      initialValues={formValues}
      validationSchema={() =>
        amValidation(
          fechaAltaMinDate,
          model.configuration,
          fields.diasReinicioLaboral.options,
        )
      }
      validate={(values) => validateRequiredFields(values, fields)}
      onSubmit={(values) => {
        if (model.configuration.requiresAttachment && files.length === 0) {
          setAttachmentError(true);
        } else if (
          values.tipoAlta === "Continúa tratamiento" &&
          especialidades.length === 0
        ) {
          //Ya estoy mostrando mensaje de error
        } else if (!professionalError) {
          handleSubmit({
            ...values,
            diasReinicioLaboral: getCantidadDiasBajaMedica(
              new Date(values.fechaReinicioLaboral),
              fields.fechaPrimeraCuracion.options,
            ),
          });
        }
      }}>
      {({ values, setFieldValue }) => (
        <Form className={classes.container}>
          <div className={classes.fieldContainer}>
            <FormSectionDivider title="Alta Médica" />
            {model.configuration.showTypeFormCombo && (
              <div className={classes.rowContainer}>
                <FormikSelect
                  name="nombreTipoFormulario"
                  label={`Tipo de Formulario Especifico ${fields.nombreTipoFormulario.required ? "*" : ""
                    }`}
                  value={formValues.nombreTipoFormulario}
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}>
                  {fields.tiposFormulario.options.map((f) => {
                    return (
                      <MenuItem key={f.nombre} value={f.nombre}>
                        {f.formulario} - {f.nombre}
                      </MenuItem>
                    );
                  })}
                </FormikSelect>
              </div>
            )}
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikDateTimePicker
                  name="fechaAlta"
                  label={`Fecha Hora Alta ${fields.fechaAlta.required ? "*" : ""
                    }`}
                  inputVariant="outlined"
                  className={classes.input}
                  disabled={disabled}
                  minDate={fechaAltaMinDate}
                  maxDate={
                    model.configuration.fechaAltaMax ? new Date() : undefined
                  }
                  autoComplete="off"
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikDatePicker
                  name="fechaReinicioLaboral"
                  label={`Fecha Reinicio Laboral ${fields.fechaReinicioLaboral.required ? "*" : ""
                    }`}
                  inputVariant="outlined"
                  className={classes.input}
                  disabled={
                    disabled || model.configuration.fechaReinicioLaboralDisabled
                  }
                  autoComplete="off"
                  minDate={addDays(values.fechaAlta, 1)}
                />
              </div>
            </div>
            {model.configuration.showDaysOff && (
              <div className={classes.rowContainer}>
                <DarkerDisabledTextField
                  label="Días totales de baja médica"
                  type="text"
                  variant="outlined"
                  value={getCantidadDiasBajaMedica(
                    new Date(values.fechaReinicioLaboral),
                    fields.fechaPrimeraCuracion.options,
                  )}
                  className={classes.input}
                />
              </div>
            )}
            <div className={classes.rowContainer}>
              <FormikTextFieldSpeech
                id="observaciones"
                name="observaciones"
                label={`Observaciones / Epicrisis ${fields.observaciones.required ? "*" : ""
                  }`}
                type="text"
                variant="outlined"
                multiline
                rows={3}
                rowsMax={10}
                className={classes.input}
                disabled={disabled}
              />
            </div>
            <div className={classes.rowContainer}>
              <FormikSelect
                name="tipoAlta"
                value={formValues.tipoAlta}
                label={`Motivo de Alta ${fields.tipoAlta.required ? "*" : ""}`}
                variant="outlined"
                className={classes.input}
                disabled={disabled}
                callBack={(value) => {
                  if (value !== "Continúa tratamiento") {
                    setEspecialidades([]);
                  } else if (especialidades.length === 0) {
                    setOpenAddEspecialidades(true);
                  }
                  if (value) {
                    const tipoAltaSelected = fields.tipoAlta.options.find(
                      (ta) => ta.nombre === value,
                    );
                    if (tipoAltaSelected) {
                      fields.fechaReinicioLaboral.required =
                        tipoAltaSelected.requiereReinicioLaboral;
                    }
                  }
                }}>
                {fields.tipoAlta.options.map((ta) => (
                  <MenuItem value={ta.nombre} key={ta.nombre}>
                    {ta.nombre}
                  </MenuItem>
                ))}
              </FormikSelect>
            </div>
            {values.tipoAlta === "Continúa tratamiento" && (
              <div>
                <FormSectionDivider title="Especialidades continúa tratamiento" />
                <div className={classes.rowContainer}>
                  <EspecialidadesContainer
                    especialidades={especialidades}
                    setEspecialidades={setEspecialidades}
                    disabled={disabled}
                    openAdd={openAddEspecialidades}
                    setOpenAdd={setOpenAddEspecialidades}
                    isView={isView}
                    idArt={idArt}
                  />
                </div>
              </div>
            )}
            <div className={classes.rowContainer}>
              {model.configuration.incapacidad ? (
                <FormikSelect
                  name="incapacidad"
                  value={formValues.incapacidad}
                  label={`Secuelas incapacitantes ${fields.incapacidad.required ? "*" : ""
                    }`}
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}>
                  {fields.incapacidad.options.map((i) => (
                    <MenuItem value={i} key={i}>
                      {i}
                    </MenuItem>
                  ))}
                </FormikSelect>
              ) : (
                <FormikRadio
                  name="incapacidad"
                  value={formValues.incapacidad}
                  label={`Secuelas incapacitantes ${fields.incapacidad.required ? "*" : ""
                    }`}
                  disabled={disabled}
                  row>
                  {fields.incapacidad.options.map((mh) => (
                    <FormControlLabel
                      value={mh.id}
                      control={<Radio />}
                      label={mh.nombre}
                      labelPlacement="end"
                      key={mh}
                    />
                  ))}
                </FormikRadio>
              )}
            </div>
            <div className={classes.rowContainer}>
              <FormikCheckbox
                name="sugerenciaRecalificacion"
                label={`Recalificacion Profesional ${fields.sugerenciaRecalificacion.required ? "*" : ""
                  }`}
                labelPlacement="end"
                disabled={disabled}
              />
            </div>
            <div className={classes.rowContainer}>
              <FormikCheckbox
                name="actividadHabitual"
                label={`Actividad Habitual ${fields.actividadHabitual.required ? "*" : ""
                  }`}
                labelPlacement="end"
                disabled={disabled}
              />
            </div>
            <div className={classes.rowContainer}>
              <FormikCheckbox
                name="prestacionesMantenimiento"
                label={`Prestaciones de Mantenimiento ${fields.prestacionesMantenimiento.required ? "*" : ""
                  }`}
                labelPlacement="end"
                disabled={disabled}
              />
            </div>
            <div className={classes.rowContainer}>
              <FormikRadio
                name="marcaFirmado"
                value={formValues.marcaFirmado}
                label="¿El trabajador firmó el documento?"
                row
                disabled={disabled}
                callBack={(value) => {
                  if (value !== "Si") {
                    setFieldValue("firmaConforme", null);
                  }
                }}>
                <FormControlLabel
                  value="Si"
                  control={<Radio />}
                  label="Si"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio />}
                  label="No"
                  labelPlacement="end"
                />
              </FormikRadio>
            </div>
            {values.marcaFirmado === "Si" && (
              <div className={classes.rowContainer}>
                <FormikRadio
                  name="firmaConforme"
                  value={formValues.firmaConforme}
                  label="¿El trabajador firmó en conformidad?"
                  row
                  disabled={disabled}>
                  <FormControlLabel
                    value="C"
                    control={<Radio />}
                    label="Si"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="D"
                    control={<Radio />}
                    label="No"
                    labelPlacement="end"
                  />
                </FormikRadio>
              </div>
            )}
            <FormSectionDivider title="Diagnóstico" />
            <div className={classes.rowContainer}>
              <FormikAutocomplete
                name="diagnosticoOMS"
                label={`Diagnóstico OMS ${fields.diagnosticoOMS.required ? "*" : ""
                  }`}
                variant="outlined"
                options={diagnosis.data}
                getOptions={() =>
                  dispatch(getFormDiagnosis(idArt))
                }
                className={classes.input}
                renderOption={(option) => `${option.id} - ${option.nombre}`}
                filterOptions={(option) => option.id + option.nombre}
                disabled={disabled}
              />
            </div>
            <div className={classes.rowContainer}>
              <FormikTextFieldSpeech
                id="diagnostico"
                name="diagnostico"
                label={`Diagnóstico Actual ${fields.diagnostico.required ? "*" : ""
                  }`}
                type="text"
                variant="outlined"
                multiline
                rows={3}
                rowsMax={10}
                className={classes.input}
                disabled={disabled}
              />
            </div>
            <FormSectionDivider title="Profesional" />
            <ProfessionalControl
              disabled={disabled}
              fields={fields}
              setFieldValue={setFieldValue}
              profesionalPreCarga={formValues.profesionalApellidoNombre}
              error={professionalError}
              setError={setProfessionalError}
              especialidadesProfesional={fields.especialidadProfesional}
              values={values}
              habilitarEspecialidadInforme={true}
              isView={isView}
              idArt={idArt}
            />

            {model.configuration.showAttachments && (
              <div>
                <FormSectionDivider title="Adjuntos" />
                <div className={classes.rowContainer}>
                  <DropZone
                    files={files}
                    setFiles={setFiles}
                    configuration={model.configuration}
                    error={attachmentError}
                    setError={setAttachmentError}
                    disabled={disabled}
                    selectedDocument={selectedDocument}
                    accept={model.configuration.availableExtensions}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={classes.actionsContainer}>
            <FormikActions handleCancel={handleClose} disabled={disabled} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Am;
